import { initialize } from '@reshima/telemetry';
import { getAuthApp } from '@reshima/firebase';

const connectionString =
  process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING || '';
const consoleTraces = process.env.NEXT_PUBLIC_CONSOLE_TRACES === 'true';
const commitHash = process.env.NEXT_PUBLIC_COMMIT_SHA;
const buildTime = process.env.NEXT_PUBLIC_BUILD_TIME;
const sessionId = Date.now();

declare global {
  interface Window {
    AndroidAppVersion?: (appVersion: string) => void;
    IosAppVersion?: (appVersion: string) => void;
  }
}

let androidAppVersion: string | undefined;
let iosAppVersion: string | undefined;

if (typeof window !== 'undefined') {
  window.AndroidAppVersion = (appVersion: string) => {
    androidAppVersion = appVersion;
  };
  window.IosAppVersion = (appVersion: string) => {
    iosAppVersion = appVersion;
  };

  window.AndroidWebInterface?.getAppVersionReady?.();
  window.webkit?.messageHandlers?.getAppVersionReady?.postMessage(null);
}

export async function initializeTelemetry() {
  await initialize({
    connectionString,
    consoleTraces,
    initialProperties: () => {
      const currentUser = getAuthApp()?.currentUser;

      return {
        ...(currentUser && {
          userId: currentUser.uid,
        }),
        ...(androidAppVersion && { androidAppVersion }),
        ...(iosAppVersion && { iosAppVersion }),
        commitHash,
        buildTime,
        sessionId,
      };
    },
  });
}
